@import "tailwindcss/base";
@import "tailwindcss/components";
@import url("components.css");

@import "tailwindcss/utilities";

.zoomable-content {
    position: relative;
    width: 100vw;
    height: 300px;
    will-change: transform;
    cursor: grab;
    touch-action: none;
    user-select: none;
    -webkit-user-select: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

html, body {
    --font-inter: ui-sans-serif;
    scroll-behavior: smooth;
    height: 100dvh;
    overscroll-behavior: none;
}

#__next {
    height: 100dvh;
}