.uxf-radio-group {
    &__options-wrapper {
        flex-wrap: wrap;
        gap: theme("spacing.4");
    }

    &__option {
        margin-left: 0;
    }
}
