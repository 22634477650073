.uxf-tabs {
    @apply w-full;

    &--grow {
        .uxf-tabs__tab {
            @apply grow;
        }
    }

    &__tab-list__wrapper {
        width: 100%;
    }

    &__tab-list {
        display: flex;
        width: 100%;
        align-items: center;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__tab {
        height: theme("size.11");
        outline: none;
        min-width: 100px;
        &--default {
            :root .light & {
                color: theme("colors.white");
            }

            &.is-active {
                @apply before:opacity-100;

                :root .light & {
                    color: theme("colors.white");
                    font-weight: theme("fontWeight.semibold");
                    border-bottom: 3px solid theme("colors.white")
                }
            }
        }
    }
}
