.uxf-avatar {
    @apply relative inline-flex size-10 items-center justify-center rounded;

    &__image {
        @apply rounded-inherit absolute left-0 top-0 block size-full object-cover;
    }

    :root .light & {
        @apply bg-gray-200 text-gray-700;
    }

    :root .dark & {
        @apply bg-gray-800 text-gray-200;
    }
}
