.uxf-list-item {
    @apply is-hoverable:opacity-75 space-x-3 transition-opacity before:absolute before:inset-0 before:rounded-lg focus-visible:before:border-2;

    position: relative;
    display: flex;
    min-height: 64px;
    flex-grow: 1;
    justify-content: space-between;
    padding: theme("space.2");
    outline: none;

    &__inner {
        width: 100%;
    }

    &__end-elements-wrapper,
    &__end-icon-wrapper {
        flex-shrink: 0;
        display: flex;
        align-items: center;
    }

    :root .light & {
        @apply focus-visible:before:border-primary;

        .uxf-list-item__end-icon-wrapper {
            color: theme("colors.neutral.300");
        }
    }

    :root .dark & {
        @apply focus-visible:before:border-primary;

        .uxf-list-item__end-icon-wrapper {
            color: theme("colors.gray.600");
        }
    }
}
