.obchodni-podminky ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

.obchodni-podminky ol > li {
     display: table;
     counter-increment: item;
     margin-bottom: 0.6em;
}

.obchodni-podminky ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

.obchodni-podminky li ol > li {
    margin: 0;
    margin-top: 1;
}

.obchodni-podminky li ol > li:before {
   content: counters(item, ".") " ";
   width: 46px;
}