.uxf-button {
    &--variant-google {
        background-color: theme("colors.white");
        border-radius: theme("borderRadius.full");
    }

    &--variant-facebook {
        background-color: #1877F2;
        border-radius: theme("borderRadius.full");
        color: theme("colors.white");
    }

    &--variant-apple {
        background-color: theme("colors.black");
        border-radius: theme("borderRadius.full");
        color: theme("colors.white");
    }
}
